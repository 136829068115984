<template>
  <b-card class="login-body mb-5 px-4">
    
    <div class="d-flex justify-content-center mb-8 mt-8 heading-container">
      <span class="svg-icon svg-icon-md text-danger">
        <i class="fa fa-lock color-danger"></i>
      </span>
      <h4 style="margin-left: 10px;">{{ $t('AUTH.ADMINISTRATOR') }}</h4>
    </div>

    <div v-if="show_initializing" class="d-flex justify-content-center mb-8 mt-8">
      
      <memlist-spinner />
    
    </div>

    <div v-else-if="show_invalid_link" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text"><p>{{$t('ACCOUNT.INVALID_LINK')}}</p></div>
    </div>

    <div v-else>

      <!-- if settings has both email and bankid -->
      <v-tabs v-if="company_settings.enable_login_email && company_settings.enable_login_bankid" background-color="white" color="accent-4" left>
        <v-tab>{{$t('ACCOUNT.TAB_BANKID')}}</v-tab>
        <v-tab>{{$t('ACCOUNT.TAB_EMAIL')}}</v-tab>
        
        <v-tab-item>
          <NativeBankIDComponent 
            class="mt-6"
            intent="user"
            type="auth"
            :company_id="company_id"
            @authenticated="authenticated"
          />
        </v-tab-item>
        
        <v-tab-item>
          <EmailPasswordLoginComponent 
            class="mt-6"
            :company_id="company_id"
          />
        </v-tab-item>
      </v-tabs>

      <!-- if settings has only email -->
      <div v-else-if="company_settings.enable_login_email">
        <EmailPasswordLoginComponent 
          :company_id="company_id"
        />
      </div>

      <!-- if settings has only bankid -->
      <div v-else-if="company_settings.enable_login_bankid">
        <NativeBankIDComponent
          intent="user"
          type="auth"
          :company_id="company_id"
          @authenticated="authenticated"
        />
      </div>

    </div>

  </b-card>
</template>

<style lang="scss" scoped>

.heading-container {
  border-radius: 0.42rem;
  border: 1px solid #d7ddff;
  margin-top: 2px;
  padding: 0.5rem 1rem;
  padding-top: 12px;
  margin-bottom: 1rem; 
}

.login-body {
  align-self: flex-start;
  border: 1px solid #d7ddff;
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_COMPANY_ID } from '@/core/services/store/common.module';
import { SET_ULOGIN_LINK } from '@/core/services/store/auth.module';

import axios from 'axios';

import NativeBankIDComponent from '@/view/components/bankid/NativeBankIDComponent.vue';

import { validationMixin } from 'vuelidate';
import EmailPasswordLoginComponent from '@/view/pages/ml/auth/Login/EmailPasswordLoginComponent.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { set_session_customer_id } from '@/core/services/member.service';


export default {
  mixins: [ validationMixin, toasts ],
  name: 'TabbedLogin',
  components: {
    NativeBankIDComponent,
    EmailPasswordLoginComponent
  },

  data() {
    return {

      show_initializing: true,

      show_invalid_link: false,

      company_id: null,

      company_settings: {}
    };
  },
 
  async mounted() {

    this.nonce = this.$route.query.nonce;

    if (this.nonce) {
      this.fetch_returned_auth();
    }

    this.company_id = this.$route.params.company_id;

    if (!this.company_id) {

      const settings = await axios.get(`/system/public`);

      this.company_id = settings.data.company_id;
      set_session_customer_id(settings.data.customer_id);
    }

    const login_link = `/ml-main-login/${this.company_id}`;

    this.$store.dispatch(SET_ULOGIN_LINK, login_link);

    this.$store.dispatch(SET_COMPANY_ID, this.company_id);

    this.company_settings = await this.get_settings(this.company_id);
  },
  methods: {

    authenticated(data) {
      if (data.selected_company_id) {
        this.$store.dispatch(SET_COMPANY_ID, data.selected_company_id);
      }

      this.$store.dispatch(LOGIN, {
        user: {
          user_id: data.id,
          email: data.name
        },
        token: data.token,
        refresh_token: data.refresh_token.refresh_token,
        expires_ts: data.refresh_token.expires_ts
      });

      this.$router.push({ name: 'ml-auth-forward' });
    
    },

    
    async fetch_returned_auth() {
      const res = await axios.post(`/native/bankid/returned`, {
        nonce: this.nonce
      });

      if (res.status === 200) {
        this.authenticated(res.data);
      }
    },


    async get_public_data() {
      try {
        const res = await axios.get(`/company/public-data`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('get_public_data', err);
      }

      return null;
    },

    async get_settings(company_id) {
      try {
        console.log('getting company ' + company_id);
        const res = await axios.get(`/company/public/${company_id}`);

        if (res.status === 200) {
          this.show_initializing = false;

          return res.data.company_settings;
        }

      }
      catch (err) {
        console.error('get_settings', err);
      }

      this.show_initializing = false;

      this.show_invalid_link = true;

      return null;
    },

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
